import React from 'react';
import Collapse from "../Collapse";
import ProTable from "@ant-design/pro-table";
import {ActionType, ProColumns} from "@ant-design/pro-components";
import {ExpandableConfig} from "rc-table/lib/interface";

interface Props {
  actionRef?: React.Ref<ActionType | undefined> | undefined;
  request?: any
  columns?: ProColumns<Record<string, any>>[] | undefined;
  expandable?: ExpandableConfig<Record<string, any>> | undefined;
  params?: any;
  showFilter?: boolean;
  totalText?: string;
  showPagination?: boolean;
  rowClassName?: string;
  handleRowClick?: (record: any) => void;
  style?: React.CSSProperties;
  className?: string;
}

const Table: React.FC<Props> = (props) => {
  return (
    <ProTable
      onRow={(record) => ({
        onClick: () => props.handleRowClick ? props.handleRowClick(record) : null, // Attach the click handler
      })}
      columnEmptyText={false}
      style={props.style}
      className={props.className}
      bordered={false}
      actionRef={props.actionRef}
      rowKey="_id"
      size={'small'}
      search={props.showFilter ? {
        searchText: 'סנן',
        resetText: 'איפוס',
        collapseRender: (collapsed) => {
          return <Collapse collapsed={collapsed}/>
        },
      } : false}
      expandable={props.expandable}
      request={props.request}
      columns={props.columns}
      params={props?.params}
      rowClassName={props?.rowClassName}
      options={{
        density: false,
        setting: false,
        reload: false
      }}
      pagination={props.showPagination ? {
        defaultPageSize: 10,
        showSizeChanger: true,
        showTitle: true,
        showPrevNextJumpers: true,
        position: ['bottomRight'],
        showTotal: (total, range) => {
          return `${range[0]} - ${range[1]} מתוך ${total} ${props?.totalText ? props.totalText : 'פריטים'} `;
        }
      } : false}
    />
  );
};

export default Table;
