import React from 'react';
import {Button, Tooltip} from 'antd';
import {CopyOutlined} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import Messages from "../antDesign/Message";

interface CopyToClipboardProps {
  text: string;
  linkUrl?: string;
  markHasLink?: boolean;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({text, linkUrl, markHasLink}) => {
  const history = useHistory();

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(text)
      .then(() => {
        Messages.Success(`"${text}" הועתק! `).then(() => {
        });
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
        Messages.Error('Failed to copy to clipboard').then(() => {
        });
      });
  };

  const handleNavigateToClientDetails = () => {
    if (linkUrl) {
      history.push(linkUrl);
    }
  };

  return (
    <div className="hover-container">
      {markHasLink && linkUrl ?
        <a
          href='#'
          className="hover-text"
          onClick={handleNavigateToClientDetails}
        >
          {text}
        </a> :
        <span onClick={() => {
          if (linkUrl) history.push(linkUrl);
        }}>
          {text}
        </span>
      }

      <Tooltip title="העתק">
        <Button
          type="link"
          icon={<CopyOutlined/>}
          className="hover-button"
          onClick={handleCopyToClipboard}
        />
      </Tooltip>
    </div>
  );
};

export default CopyToClipboard;
