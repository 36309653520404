import React, {useEffect, useState} from 'react';
import Modal from "../../../antDesign/Modal";
import {Button, Col, Form, Input, Row, Segmented} from "antd";
import {MailOutlined, PhoneOutlined, LinkOutlined} from "@ant-design/icons";
import Tooltip from "../../../antDesign/Tooltip";
import {getLoggedInUserId} from "../../../../services/auth";
import Message from "../../../antDesign/Message";
import {registrationLinkClient} from "../../../../services/user";
import Messages from "../../../antDesign/Message";
import PATTERNS from "../../../../helpers/Patterns";

interface Props {
  visible: boolean;
  onClose: () => void;
}

const LinkRegistrationClient: React.FC<Props> = (props) => {

  const [form] = Form.useForm();
  const [segmentedValue, setSegmentedValue] = useState<string | number>('email');
  const [link, setLink] = useState<string | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const handleOk = (values: any) => {
    const type = segmentedValue === 'email' ? 'EMAIL' : 'PHONE';
    values = {...values, type};
    console.log(values);
    setSubmitting(true);

    registrationLinkClient(values).then(response => {
      if(response.status === 'SUCCESS') {
        Messages.Success('האימייל נשלח בהצלחה').then(() => {});
        props.onClose();
      }else {
        Messages.Error('שליחת האימייל נכשלה').then(() => {});
      }
      setSubmitting(false);
    }).catch(error => {
      Messages.Error(error.displayMessage).then(() => {});
      setSubmitting(false);
    })
  }

  const resetValues = () => {
    setSubmitting(false);
    form.resetFields();
  }

  const handleAfterClose = () => {
    resetValues();
  }

  const onModalOpen = () => {
    setLink(`${document.location.origin}/signature?aid=${getLoggedInUserId()}`);
  }

  useEffect(() => {
    if (props.visible) {
      onModalOpen()
    }
    return () => {};
  }, [props.visible])

  return (
    <Modal
      open={props.visible}
      title='שליחת קישור הרשמה ללקוח'
      onCancel={props.onClose}
      afterClose={handleAfterClose}
      footer={[
        <Button key="cancel" onClick={props.onClose}>
          ביטול
        </Button>,
        <Button loading={submitting} key="send" type="primary" onClick={() => form.submit()}>
          שלח
        </Button>,
      ]}>
      <Form form={form}
            initialValues={{
              'appendixDocuments': ['appendixA', 'appendixB', 'appendixE'],
            }}
            layout="vertical"
            onFinish={handleOk}>

        <Row gutter={[16, 16]}>

          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} style={{textAlign: "center"}}>
            {/*@ts-ignore*/}
            <Segmented
              options={[
                {
                  label: 'אימייל',
                  value: 'email',
                  icon: <MailOutlined/>
                },
                {
                  label: 'נייד',
                  value: 'phone',
                  icon: <PhoneOutlined/>
                }
              ]}
              value={segmentedValue}
              onChange={setSegmentedValue}/>

            <div style={{position: "absolute", top: 0, right: 0}}>
              <Tooltip title='העתקת הקישור'>
                <Button
                  type='link'
                  icon={<LinkOutlined />}
                  onClick={() => {
                    if(link) {
                      navigator.clipboard.writeText(link);
                      Message.Success('הקישור הועתק בהצלחה').then(() => {});
                    }else {
                      Message.Error('ההעתקה נכשלה').then(() => {});
                    }
                  }}
                />
              </Tooltip>
            </div>

          </Col>

          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              label={segmentedValue === 'email' ? "אימייל" : "טלפון"}
              rules={segmentedValue === 'email' ?
                [{type: 'email', message: 'אימייל לא תקין'}, {required: true, message: "שדה חובה"}] :
                [{
                  required: true,
                  message: "שדה חובה"
                }, {pattern: PATTERNS.MOBILE, message: "טלפון לא חוקי"}]}
              name='value'>
              <Input placeholder={segmentedValue === 'email' ? "אימייל" : "טלפון"} />
            </Form.Item>
          </Col>
        </Row>

      </Form>

    </Modal>
  );
};

export default LinkRegistrationClient;
