import React from 'react';
import {Button, Col, Row, Typography} from "antd";

const {Text} = Typography;

type options = 'error' | 'success'

interface Props {
  type: options,
  firstNameAgent?: string;
  lastNameAgent?: string;
  onClose?: () => void;
}

const Result: React.FC<Props> = (props) => {
  return (
    <Row>
      <Col lg={24} md={24} sm={24} xs={24} className='text-center'>
        {props.type === 'success' && <>
          <div style={{fontSize: 16, fontWeight: 400, paddingBottom: 16}}>
            <div className="mb-4">
              <i className="mdi mdi-check-circle-outline text-success display-4"/>
            </div>
            <div><Text>בקשתך התקבלה בהצלחה.</Text></div>
            <div><Text>היא תעובד ותטופל בהקדם האפשרי</Text></div>
            <div><Text>תודה מראש, {props.firstNameAgent + ' ' + props.lastNameAgent}</Text></div>

          </div>
        </>
        }

        {props.type === 'error' &&
          <>
            <div className="mb-4">
              <i className="mdi mdi-close-circle-outline text-danger display-4"/>
            </div>
            <div><Text>קישור לא תקין</Text></div>
            <div><Text>פנה לסוכן לקבלת קישור חדש</Text></div>
          </>
        }

      </Col>
    </Row>
  );
};

export default Result;
