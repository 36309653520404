export const addressConcatenation = (city: string, street?: string, houseNumber?: number, apartmentNumber?: number) => {
  const cleanCity = city ? city.trim() : '';
  const cleanStreet = street ? street.trim() : '';

  // Convert house number to string if exists
  const houseNumberStr = houseNumber ? houseNumber.toString() : '';

  // Build the house/apartment part
  const houseApartmentPart = apartmentNumber
    ? `${houseNumberStr}/${apartmentNumber}`
    : houseNumberStr;

  // Combine street and house/apartment part
  const streetAddressPart = houseApartmentPart
    ? `${cleanStreet} ${houseApartmentPart}`
    : cleanStreet;

  // Final address format: street address, city
  return streetAddressPart
    ? `${streetAddressPart}, ${cleanCity}`
    : cleanCity;
}

export default {addressConcatenation}
