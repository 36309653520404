import React, {useEffect, useState} from 'react';
import {Button, Col, Modal, Progress, Row, Select, Tooltip, Typography, Upload, UploadProps} from "antd";
import {UploadFile} from "antd/es/upload/interface";
import {getLoggedInUser} from "../../../../../services/auth";
import {uploadZipFile} from "../../../../../services/client";
import {DeleteOutlined} from "@ant-design/icons";
import {getUsersByType} from "../../../../../services/user";
import Messages from "../../../../antDesign/Message";

const {Text} = Typography;
const {Option} = Select;
const {Dragger} = Upload;

interface Props {
  onUploaded: (clientId: string) => void;
  maxCount: number;
  accept: string;
}

const Uploader: React.FC<Props> = (props) => {
  const [agents, setAgents] = useState<any>([]);
  const [agentSelected, setAgentSelected] = useState<string | undefined>(undefined);
  const [isExistingClient, setIsExistingClient] = useState<boolean>(false);
  const [clientToUpdate, setClientToUpdate] = useState<string>('');
  const [filesList, setFilesList] = useState<UploadFile[]>([]);

  const uploadProps: UploadProps = {
    name: 'file',
    maxCount: props.maxCount,
    multiple: true,
    accept: props.accept,
    beforeUpload: (file) => {
      return false;
    },
    itemRender: (originNode, file, currFileList, actions) => {
      return (
        <>
          <Row className='mt-3 px-3'>
            <Col xl={6} lg={6} md={8} sm={8} xs={8}>
              <Text strong>{file.name}</Text>
            </Col>
            <Col xl={17} lg={17} md={15} sm={15} xs={15}>
              <Progress percent={file.percent}/>
            </Col>
            <Col xl={1} lg={1} md={1} sm={1} xs={1} style={{textAlign: 'left'}}>
              <Tooltip title="מחיקה">
                <DeleteOutlined
                  style={{color: 'red'}}
                  onClick={() => actions.remove()}
                />
              </Tooltip>
            </Col>
          </Row>
          {
            currFileList && currFileList.length > 0 &&
              <>
                {
                  getLoggedInUser().type === 'treatment' &&
                    <Row className="mt-4">
                        <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                            <Select defaultValue="" style={{width: 250}}
                                    onChange={(value) => setAgentSelected(value)}>
                              {
                                agents && agents.length > 0 && agents.map((agent: any) => <Option
                                  key={agent.value} value={agent.value}>{agent.label}</Option>)
                              }
                            </Select>
                        </Col>
                    </Row>
                }

                  <Row justify="end" gutter={[10, 0]} className='mt-5'>
                      <Col>
                          <Button onClick={() => {
                            actions.remove();
                          }}>נקה</Button>
                      </Col>
                      <Col>
                          <Button type="primary" onClick={() => {
                            uploadFiles(currFileList, undefined, undefined);
                          }}>העלאה</Button>
                      </Col>
                  </Row>
              </>
          }
        </>
      )
    }
  };

  const uploadFiles = (files: UploadFile[], clientId: string | undefined, isOverwrite: boolean | undefined) => {
    files.forEach((file) => {
      const items = [25, 50, 75];
      file.percent = items[Math.floor(Math.random() * items.length)];
    })

    const filesUpload: any = [];

    files.forEach(file => {
      filesUpload.push(file.originFileObj);
    })

    const body: any = {
      files: filesUpload
    }

    if (getLoggedInUser().type === 'treatment')
      body.agent_id = agentSelected;

    if(clientId !== undefined)
      body.clientId = clientId;

    if(isOverwrite !== undefined)
      body.isOverwrite = isOverwrite;

    let allFilesUploaded = true;

    uploadZipFile(body).then(response => {
      if(response.status === 'ERROR'){
        if(response.code === 409){
          setIsExistingClient(true);
          setClientToUpdate(response.clientId);
          setFilesList(files);
        }
      }
      else {
        files.forEach((file) => {
          file.percent = 100;

          const resFileStatus = response.data.filter((res: any) => {
            console.log('originalname', res.file.originalname);
            console.log('name', file.name);

            if (res.status.toString().toUpperCase() !== 'SUCCESS')
              allFilesUploaded = false;

            return res.file.originalname === file.name;
          })[0];
          // file.responseMsg = resFileStatus.data.displayMessage;
          // file.responseStatus = resFileStatus.status;
          return file;
        })

        if (allFilesUploaded) {
          Messages.Success('טעינת קובץ בוצעה בהצלחה').then(() => {});
          setIsExistingClient(false);
          props.onUploaded(response.data[0].data.clientId);
        } else {
          Messages.Error('טעינת קובץ נכשלה').then(() => {});
        }

      }
    });
  }

  const getAgentsList = () => {
    getUsersByType({type: 'agent'}).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        const options = response.users.map((agent: any) => {
          return {
            label: agent.firstName + ' ' + agent.lastName,
            value: agent._id
          }
        })
        setAgents(options);
      }
    })
  }

  useEffect(() => {
    getAgentsList();
  }, [])

  return (
    <>
      <Dragger
        {...uploadProps}
        style={{border: 'border: 1px solid #eff2f7'}}
        height={250}>
        <p className="ant-upload-drag-icon">
          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
        </p>
        <p className="ant-upload-text">גרור קובץ מסלקה או לחץ להעלאה</p>
      </Dragger>

      <Modal
        open={isExistingClient}
        title="אישור עדכון פרטי עמית/ה"
        closable={false}
        footer={[
          <Button key="no" onClick={() => uploadFiles(filesList, clientToUpdate, false)}>
            לא
          </Button>,
          <Button key="yes" type="primary" onClick={() => uploadFiles(filesList, clientToUpdate, true)}>
            כן
          </Button>
        ]}
      >
        <p>לקוח זה קיים במערכת, האם לעדכן את פרטי העמית/ה?</p>
      </Modal>
    </>
  );
};

export default Uploader;
